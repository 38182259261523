import React from 'react';
import { ArrowRight } from '../../../Svg';
import styled from 'styled-components';
import {
  bodyBold,
  Color,
  columnSpacing,
  displayNoneByBreakpoint,
  flex,
  h2Desktop,
  h2Mobile,
  marginLeft,
  marginTop,
  maxWidth,
  paddingTop,
  typographyByBreakpoint,
} from '../../../../styles';
import { Link as BaseLink } from 'gatsby';

const Card = styled.div`
  ${marginLeft(0, 0, 107)};
  position: relative;
  z-index: 1;
`;

const VideoCardHeader = styled.div`
  border-top: 1px solid ${Color.SEAFOAM_GREEN};
  ${paddingTop(32, 32, 49)};
`;

const Link = styled(BaseLink)`
  ${flex('flex-start', 'center')}
  ${columnSpacing(24, 24, 16)};

  span {
    text-decoration: underline;
    ${bodyBold};
    color: ${Color.BLACK};
    ${displayNoneByBreakpoint('block', true, true, false, true)};
  }
`;

const VideoCardContainer = styled.div`
  ${flex('space-between', 'center')};
  ${marginTop(8, 8, 16)};
  ${columnSpacing(24, 24, 32)};
`;

const VideoTitle = styled.h3`
  ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};
  ${maxWidth('unset', 'unset', '79.5rem')};
`;

export const VideoCard = ({ title, url }: { title: string; url: string; }) => (
  <Card>
    <VideoCardHeader>
    </VideoCardHeader>
    <VideoCardContainer>
      <VideoTitle>{title}</VideoTitle>
      {!!url && (
        <Link target="_blank" to={url}>
          <span>Watch Video</span>
          {ArrowRight}
        </Link>
      )}
    </VideoCardContainer>
  </Card>
);
