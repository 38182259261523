export const VIDEO_DATA = [
    {
      title: `Interview with Ready Life CEO, Ashley Bell and Advisory Council Chair, Dr. Bernice A. King`,
      url: `https://www.youtube.com/watch?v=1mGQUo83bDM`,
    },
    {
        title: `Interview | Jonathan Beane x Ashley Bell x Dr. Bernice A. King`,
        url: `https://www.youtube.com/watch?v=Zi5UloyVIEc`,
    },
  ];
  