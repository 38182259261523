import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  marginTop,
  bodyBold,
  gridColumns,
  rowSpacing,
  Color,
  typographyByBreakpoint,
  h1Mobile,
  h1Desktop,
  h2Mobile,
  h2Desktop,
  h3Desktop,
  paddingTop,
  paddingBottom,
  maxWidth,
  marginLeft,
  columnSpacing,
  Width,
  xxlMobile,
  xxlDesktop,
  hover,
  flex,
  position,
  width,
  height,
  owlSelector,
  marginTopBottom,
} from '../../../../styles';
import { BlogCard } from './BlogCard';
import { VIDEO_DATA } from '../../../../data/page/blogs/VideoData'
import { VideoCard } from './VideoCard';

const Wrapper = styled.section`
  ${marginTopBottom([136, 80], [152, 96], [206, 120])};
  ${maxWidth('32.1rem', '68.8rem', '144rem')};
  margin-inline: auto;
`;

const BlogWrapper = styled.div`
  ${columnSpacing(0, 48, 159)};
  //added z-index and position relative for EventWrapper & Card to make Vector component not overlapping divs
  position: relative;
  z-index: 1;
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    ${flex('flex-start', 'center')};
  }
`;

const CardSection = styled.div`
  margin-inline: auto;
  ${maxWidth('32.1rem', '57rem', '120rem')};
  ${marginTop(48, 48, 64)};
  ${owlSelector('top', 32, 32, 48)};
`;

const Header = styled.h1`
  ${typographyByBreakpoint(xxlMobile, xxlMobile, xxlDesktop)};
  color: ${Color.SEAFOAM_GREEN};
`;

const Tab = styled.div`
  ${marginTop(32, 0)};
  ${flex('flex-start')};
  column-gap: 3.2rem;
`;

const TabButton = styled.button<{ currentActive: boolean }>`
  ${h3Desktop};
  color: ${Color.BLACK};
  padding-bottom: 0.4rem;

  ${({ currentActive }) =>
    currentActive
      ? css`
          border-bottom: 3px solid ${Color.BLACK};
        `
      : css`
          ${hover('opacity', 0.55, 1)};
          border-bottom: 3px solid transparent;
        `}
`;

const Vector = styled.div`
  z-index: -1;
  position: absolute;
  ${position('top', -148, -278, -214)};
  ${position('right', -152, -159, -206)};
  ${width('39.1rem', '61rem', '88rem')};
  ${height('39.1rem', '61rem', '88rem')};
  border-radius: 50%;
  background: linear-gradient(136.25deg, rgba(130, 138, 188, 0) 14.98%, rgb(198 200 212 / 36%) 93.71%);
  opacity: 0.5;
  transform: matrix(0.97, -0.26, -0.26, -0.97, 0, 0);
  overflow: hidden;
`;

const BlogCards = ( {data}) => {
  const allBlogs = data && data.allBlogs && mapEdgesToNodes(data.allBlogs)
  
  const allVideos = VIDEO_DATA;

 
  const videos = allVideos?.map((event, index) => 
  <VideoCard key={index} {...event} />);

  const blogs = allBlogs?.map((event, index) => 
    <BlogCard key={index} {...event} />);

  const [currentData, setCurrentData] = useState(blogs);
  const [active, setActive] = useState(true);
  
  return (
    <Wrapper>
      <BlogWrapper>
        <Vector />
        <Header>Blogs</Header>
        <Tab>
          <TabButton
            // @ts-ignore
            currentActive={active}
            onClick={() => {
              // @ts-ignore
              setCurrentData(blogs);
              setActive(!active);
            }}>
            Blogs
          </TabButton>
          <TabButton
            // @ts-ignore
            currentActive={!active}
            onClick={() => {
              // @ts-ignore
              setCurrentData(videos);
              setActive(!active);
            }}>
            Videos
          </TabButton>
        </Tab>
      </BlogWrapper>
      {currentData ? <CardSection>{currentData}</CardSection> : <CardSection>{blogs}</CardSection>}
      
    </Wrapper>
  );
};

function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export default BlogCards;
