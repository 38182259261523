import React from 'react';
import { ArrowRight } from '../../../Svg';
import styled from 'styled-components';
import {
  bodyBold,
  Color,
  columnSpacing,
  displayNoneByBreakpoint,
  flex,
  marginLeft,
  maxHeight,
  maxWidth,
  paddingTop,
  typographyByBreakpoint,
  h3DesktopBlog,
  h3TabletBlog,
  h3MobileBlog,
  Width,
  widths
} from '../../../../styles';
import { Link as BaseLink } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Card = styled.div`
  ${marginLeft(0, 0, 107)};
  position: relative;
  z-index: 1;
`;

const BlogCardHeader = styled.div`
  border-top: 1px solid ${Color.SEAFOAM_GREEN};
  ${paddingTop(32, 32, 49)};
`;

const Link = styled(BaseLink)`
  ${flex('flex-start', 'center')}
  ${columnSpacing(24, 24, 24)};
  span {
    text-decoration: underline;
    ${bodyBold};
    color: ${Color.BLACK};
    margin-left: 1rem;
    ${displayNoneByBreakpoint('block', false, true, false, true)};
  }
`;

const BlogCardContainer = styled.div`
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    ${flex('space-evenly', 'center')}
    ${columnSpacing(24, 24, 24)};
  }

  @media (max-width: ${Width.BREAK_SM}) {
    Container {
      flex-direction: column;
    }
  }
`;

const BlogTitle = styled.h3`
  ${typographyByBreakpoint(h3MobileBlog, h3TabletBlog, h3DesktopBlog)};
  ${maxWidth('unset', 'unset', '65rem')};
`;

const ImageColumn= styled.div`
  flex: 1;
  margin: 10px;
`;

const TitleColumn= styled.div`
  flex: 3;
  margin: 10px;
`;

const LinkColumn= styled.div`
  @media print, screen and (min-width: ${Width.BREAK_LG}px) {
  flex: 1;
  margin: 10px;
  ${flex('flex-end', 'center')}
  }

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    flex: 1;
    margin: 10px;
    ${flex('flex-end', 'center')}
    }

  @media (max-width: ${Width.BREAK_SM}) {
    flex: 1;
    margin: 10px;
    ${flex('flex-start', 'center')}
    }
  }
`;

const BlogPageImageContainer = styled.div`
  ${maxWidth('25rem', '27.7rem', '48.1rem')};
  ${maxHeight('28.8rem', '30.9rem', '43.3rem')};
  .main-image {
    ${widths('25rem', '25rem', '30rem')};
  }
`;

export const BlogCard = ({ title, slug, mainImage }: { title: string; slug: any; mainImage: any }) => (
  <Card>
    <BlogCardHeader>
    </BlogCardHeader>
    <BlogCardContainer>
      <ImageColumn><BlogPageImageContainer>{!mainImage ? null : <GatsbyImage className={'main-image'} image={mainImage.asset.gatsbyImageData} alt ="Blog Image"/>}</BlogPageImageContainer></ImageColumn>
      <TitleColumn><BlogTitle>{title}</BlogTitle></TitleColumn>
      <LinkColumn>{!!slug && (
        <Link to={slug.current}>
          <span>Read More</span>
          {ArrowRight}
        </Link>
      )}</LinkColumn>
    </BlogCardContainer>
  </Card>
);