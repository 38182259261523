import React from 'react';
import {graphql} from "gatsby";
import WebsiteDefaultPage from '../../components/Page/Base';
import BlogCards from '../../components/PageSpecific/community/blogs/BlogCards'
import { JoinSection } from '../../components/SharedSections';
import { Color } from '../../styles';
import { LIGHT_DARK } from '../../constants';

const Blogs = ({data}) => {
  return (
    <WebsiteDefaultPage bodyBackground={Color.OFF_WHITE} pageState={{ theme: LIGHT_DARK.LIGHT }}>
      <BlogCards data = {data}/>
      <JoinSection />
    </WebsiteDefaultPage>
  );
};

export default Blogs;

export const query = graphql`
query BlogsQuery {
  allBlogs: allSanityBlogs(sort: {fields: [publishedAt], order: DESC}) {
    edges {
      node {
        id
        title
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(fit: FILL, placeholder: BLURRED)
            url
          }
        }
        publishedAt(formatString: "MMMM Do, YYYY")
      }
    }
  }
}
`